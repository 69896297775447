<script setup lang="ts">
import { Button, Image } from 'ant-design-vue'
import { getPreviewDeviceRelationsApi } from '@/api/relation'
import { closeSocket, getWebSocket, openSocket } from '@/utils/websocket/WebsocketAction'
import emitter from '@/utils/mitt'

const props = defineProps<{ node: MenuNode }>()

const relations = ref<PreviewDeviceRelation[]>([])
const open = ref(false)

const search = ref<TableSearchType[]>([])
const state = reactive<TableState>({
  data: [],
  columns: [
    { title: '设备名称', dataIndex: 'deviceName' },
    { title: '图标', dataIndex: 'iconPath', width: 80, customRender: ({ record }) => { return h(Image, { src: record.iconAccessPath, width: 40, preview: false }) } },
    { title: '设备编号', dataIndex: 'deviceCode' },
    { title: '所属设备模型', dataIndex: 'deviceModelName' },
    { title: '子系统名称', dataIndex: 'subSysName' },
    { title: '操作', dataIndex: '', width: 120, customRender: ({ record }) => { return (h(Button, { type: 'primary', size: 'small', onClick: () => onDetail(record) }, () => '详情')) } },
  ],
  config: {
    total: 0, // 列表总数
    loading: false, // loading 加载
    isBorder: false, // 是否显示表格边框
    isSerialNo: true, // 是否显示表格序号
    isSelection: true, // 是否显示表格多选
    isOperate: true, // 是否显示表格操作栏
  },
  pagination: {},
})

const selectedRelation = ref<PreviewDeviceRelation>()

function onDetail(data: PreviewDeviceRelation) {
  open.value = true
  selectedRelation.value = data
}

function onSearch(form: any) {
  if (!form.deviceModelCode) {
    state.data = relations.value
  }
  else {
    state.data = relations.value.filter(relation => relation.deviceModelCode === form.deviceModelCode)
  }
}

watchEffect(async () => {
  // 获取点位
  await getRelations()
  // 初始化搜索栏
  initSearchConfig()
  // 订阅设备
  subscribeDevices()
})

onMounted(() => {
  // 连接websocket
  openSocket()
  emitter.on('onDeviceIconChenged', afterDeviceIconChanged)
})

onBeforeUnmount(closeSocket)

function afterDeviceIconChanged(data: any) {
  const { deviceCode, iconPath } = data
  const aimRelation = relations.value.find(relation => (relation.deviceCode === deviceCode))
  if (!aimRelation)
    return
  aimRelation.iconAccessPath = iconPath
}

// 获取点位
async function getRelations() {
  relations.value = await getPreviewDeviceRelationsApi(Number(props.node.targetDetail))
  state.data = relations.value
}

// 订阅设备
function subscribeDevices() {
  const deviceCodes = relations.value.map(relation => relation.deviceCode ?? '')
  getWebSocket().onSubscribeDevice(deviceCodes)
}

// 初始化搜索表单
function initSearchConfig() {
  search.value.splice(0)

  const deviceModelOptions = relations.value.map(relation => ({ label: relation.deviceModelName, value: relation.deviceModelCode })).reduce<Option[]>((pre, cur) => {
    if (pre.findIndex(option => option.value === cur.value) !== -1) {
      return pre
    }
    else {
      return [...pre, cur]
    }
  }, [])

  search.value.push({ label: '设备模型', name: 'deviceModelCode', placeholder: '请选择设备模型', type: 'select', required: false, options: deviceModelOptions })
}

function onCloseDetail() {
  open.value = false
}
</script>

<template>
  <div h-full flex-col flex overflow-hidden mt-10px pos-relative class="drawer-container">
    <search-form :search="search" @search="onSearch" />
    <BaseTable v-bind="state" :need-header="false" />
  </div>
  <DeviceRuntime :visible="open" :device-code="selectedRelation?.deviceCode" :device-name="selectedRelation?.deviceName" @close="onCloseDetail" />
</template>

<style lang='scss' scoped>

</style>
