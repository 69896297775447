/**
 * 展示层数字
 */
export const ENABLE_LAYER = 1;
/**
 * 隐藏层数字
 */
export const DISABLE_LAYER = 2;

/**
 * 2D地图的宽
 */
export const MAP_2D_WIDTH = 1920;

/**
 * 2D地图的高
 */
export const MAP_2D_HEIGHT = 1080;
