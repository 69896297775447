<script setup lang="ts">
import { getRuntimeStatisticApi } from '@/api/statistic'
import type { ECOption } from '@/components/echarts/config'

const props = defineProps(['previewId', 'visible'])
const emits = defineEmits(['cancel'])

const nativeRuntimeStatistics = ref<RuntimeStatistic[]>([])
const timer = ref<any>()
const refreshButtonDisabled = ref<boolean>(false)

watch(() => props.visible, async (visible) => {
  if (visible) {
    await onRefresh()
  }
})

onBeforeUnmount(() => {
  clearTimeout(timer.value)
})

async function onRefresh() {
  clearTimeout(timer.value)
  refreshButtonDisabled.value = true
  timer.value = setTimeout(() => {
    refreshButtonDisabled.value = false
  }, 2000)
  const res = await getRuntimeStatisticApi(Number(props.previewId))
  nativeRuntimeStatistics.value = res
}

function onCancel() {
  emits('cancel')
}

function getOption(runtimeStatisticStateGroup: RuntimeStatisticStateGroup) {
  const data = runtimeStatisticStateGroup.states.map(item => ({ name: item.stateName ?? '未知', value: item.count }))
  return {
    title: [{
      text: runtimeStatisticStateGroup.stateGroupName,
      top: '45%',
      left: '29%',
      textAlign: 'center',
      textStyle: {
        fontSize: 16,
        fontWeight: 400,
        color: '#000',
        overflow: 'breakAll',
        width: 100,
        lineHeight: 20,
      },
    }],
    tooltip: {
      trigger: 'item',
      formatter: '{b} :  {c}',
    },
    legend: {
      show: true,
      top: 'middle',
      right: '80px',
      icon: 'circle',
      orient: 'vertical',
      align: 'auto',
      itemWidth: 8,
      itemGap: 20,
      formatter(name: string) {
        let text = ''
        data.forEach((val) => {
          if (val.name === name) {
            text = `${name} ${val.value}`
          }
        })

        return text
      },
    },
    series: [{
      type: 'pie',
      radius: ['65%', '90%'],
      center: ['30%', '50%'],
      data,
      label: { show: false },
      labelLine: { show: false },
      color: ['#3BDCFF', '#BFEAFB', '#15E9C3', '#15C3E9', '#00FFFF', '#4AEAB0'],
    }],
  } as ECOption
}
</script>

<template>
  <a-modal
    :open="visible" width="100%" wrap-class-name="full-modal" :footer="null" :z-index="999999"
    @cancel="onCancel"
  >
    <template #title>
      设备统计
    </template>
    <div h-full overflow-y-scroll>
      <div v-for="(item, index) in nativeRuntimeStatistics" :key="`${index}runtimeStatistics`">
        <a-divider orientation="left">
          {{ item.deviceModelName }} {{ item.totalDevices }}
        </a-divider>
        <div class="pies-container">
          <div v-for="(itemInner, indexInner) in item.stateGroups" :key="`${indexInner}stateGroups`" class="pies-item">
            <echarts :option="getOption(itemInner)" />
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<style lang="scss">
.full-modal {
  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
    overflow: hidden;
  }

  .ant-modal-body {
    flex: 1;
    overflow: hidden;
  }
}
</style>

<style lang="scss" scoped>
.divider-code {
  margin-left: 5px;
}

.pies-container {
  display: flex;
  flex-wrap: wrap;

  .pies-item {
    width: 33%;
    margin-bottom: 10px;
    height: 200px;
  }
}
</style>
