const { localVector3ToVector3ViewConfig, vector3ToLocalVector3ViewConfig } = usePointTranslate()

/**
 * 地图与设备关系列表
 */
export async function getPreviewDeviceRelationsApi(previewId: number, runtimeIcon: boolean = true) {
  const res = await useGet<PreviewDeviceRelation[]>('/engine/preview-device-relation/list', { previewId, runtimeIcon })

  res.forEach((item) => {
    item.axisX = Number(item.axisX)
    item.axisY = Number(item.axisY)
    item.axisZ = Number(item.axisZ)
    if (item.threeViewCamera != null) {
      const parsedThreeViewCamera = JSON.parse(item.threeViewCamera as string)
      item.threeViewCamera = localVector3ToVector3ViewConfig(parsedThreeViewCamera as ViewConfig<LocalVector3>)
    }
  })

  return res
}

/**
 * 单个新增&修改地图和设备关系
 */
export async function updatePreviewDeviceRelationApi(relation: PreviewDeviceRelation) {
  if (relation.threeViewCamera != null) {
    const localVector3Config = vector3ToLocalVector3ViewConfig(relation.threeViewCamera)
    relation.threeViewCamera = JSON.stringify(localVector3Config)
  }

  return await usePost<PreviewDeviceRelation>('/deploy/preview-device-relation', relation)
}
