import * as THREE from 'three'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

export default class Loader {
  // 点位加载管理器
  deviceLoadingManager: THREE.LoadingManager
  // 3D地图加载管理器
  dracoLoaderManager: THREE.LoadingManager
  // 平面地图加载管理器
  previewMapLoadingManager: THREE.LoadingManager
  textureLoader: THREE.TextureLoader
  previewLoader: THREE.TextureLoader
  private gltfLoader: GLTFLoader

  constructor() {
    this.deviceLoadingManager = new THREE.LoadingManager()
  }

  /**
   * 打开gltfLoader
   */
  openGLTFLoader() {
    this.dracoLoaderManager = new THREE.LoadingManager()
    this.gltfLoader = new GLTFLoader(this.dracoLoaderManager)
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('/three/')
    this.gltfLoader.setDRACOLoader(dracoLoader)
    return this.gltfLoader
  }

  /* *
	 * @description 打开平面地图loader
	 */
  openPreviewLoader(onLoad?: () => void) {
    this.previewMapLoadingManager = new THREE.LoadingManager()
    this.previewMapLoadingManager.onLoad = function () {
      onLoad && onLoad()
    }
    this.previewLoader = new THREE.TextureLoader(this.previewMapLoadingManager)
  }

  /**
   * 打开textureLoader
   */
  openTexturesLoader(onLoad?: () => void) {
    this.deviceLoadingManager = new THREE.LoadingManager()
    this.deviceLoadingManager.onLoad = function () {
      onLoad && onLoad()
    }
    this.textureLoader = new THREE.TextureLoader(this.deviceLoadingManager)
  }
}
