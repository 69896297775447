import * as THREE from 'three'

class ResourceTracker {
  resources: Set<any>

  constructor() {
    this.resources = new Set()
  }

  track(resource: any) {
    if (!resource) {
      return resource
    }

    // 当material是一个数组material时，处理它的子元素
    if (Array.isArray(resource)) {
      resource.forEach(resource => this.track(resource))
      return resource
    }

    if (resource.dispose || resource instanceof THREE.Object3D) {
      this.resources.add(resource)
    }

    if (resource instanceof THREE.Object3D) {
      this.track((resource as any).geometry)
      this.track((resource as any).material)
      this.track(resource.children)
      // 遍历这些材质的属性和uniforms来寻找纹理
    }
    else if (resource instanceof THREE.Material) {
      // 检查material上是否有texture
      for (const value of Object.values(resource)) {
        if (value instanceof THREE.Texture) {
          this.track(value)
        }
      }

      // 须检查是否有uniforms引用纹理或纹理数组
      if ((resource as any).uniforms) {
        for (const value of Object.values((resource as any).uniforms)) {
          if (value) {
            const uniformValue = (value as any).value
            if (uniformValue instanceof THREE.Texture || Array.isArray(uniformValue)) {
              this.track(uniformValue)
            }
          }
        }
      }
    }

    return resource
  }

  untrack(resource: any) {
    this.resources.delete(resource)
  }

  dispose() {
    for (const resource of this.resources) {
      if (resource instanceof THREE.Object3D) {
        if (resource.parent) {
          resource.parent.remove(resource)
        }
      }
      if (resource.dispose) {
        resource.dispose()
      }
    }

    this.resources.clear()
  }
}

const resTracker = new ResourceTracker()
const track = resTracker.track.bind(resTracker)

export { track, resTracker }
