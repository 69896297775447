<script setup lang="ts">
import { ref } from 'vue'
import { EnvironmentOutlined } from '@ant-design/icons-vue'
import type { Key } from 'ant-design-vue/es/_util/type'
import { isEmpty } from 'lodash-es'
import Blank from './components/blank.vue'
import List from './components/list.vue'
import Preview from './components/preview.vue'
import { getMenusApi } from '@/api/menu'
import { getPreviewsApi } from '@/api/preview'

const visible = ref<boolean>(false)
const viewType = ref('preview')
const menuOpenKeys = ref<Key[]>([])
const menuSelectedKeys = ref<Key[]>([])
const selectedNode = ref<MenuNode>()
// 平面视图列表
const previews = ref<Preview[]>([])
const selectedPreview = ref<Preview>()
// 菜单数据
const menuData = ref<Tree<MenuNode>[]>([])

const { forEachTree, findRootFirstChild, findAllParents, getNodeById } = useTree(menuData)
const route = useRoute()

onMounted(async () => {
  await getPreviews()
  await getMenuData()

  const nodeId = route.query.id
  if (nodeId) {
    initNode(Number(nodeId))
  }
  else {
    initNode()
  }
})

// 获取所有平面视图
async function getPreviews() {
  previews.value = await getPreviewsApi()
}

async function getMenuData() {
  menuData.value = await getMenusApi()
  formatTree()
}

function formatTree() {
  forEachTree((node) => {
    if (node.children && node.children.length === 0) {
      delete node.children
    }
    node.key = node.value
    delete node.id
  })
}

function initNode(nodeId?: number) {
  if (menuData.value.length === 0)
    return

  if (nodeId != null) {
    let aimNode = getNodeById(nodeId)

    if (aimNode?.node?.openTarget === 'CHILDREN') {
      aimNode = findRootFirstChild(aimNode) as any
    }

    if (aimNode == null)
      return

    menuOpenKeys.value = findAllParents(aimNode?.node.id, false).map(node => String(node.node.id))
    menuSelectedKeys.value = findAllParents(aimNode?.node.id).map(node => String(node.node.id))
    selectedNode.value = aimNode.node
  }
  else {
    const firstTree = menuData.value[0]
    const rootFirstNode = findRootFirstChild(firstTree)
    menuOpenKeys.value = findAllParents(rootFirstNode?.node.id, false).map(node => String(node.node.id))
    menuSelectedKeys.value = findAllParents(rootFirstNode?.node.id).map(node => String(node.node.id))
    selectedNode.value = rootFirstNode?.node
  }
  getPreviewId() && selectPreview(getPreviewId())
}

function openOrClose() {
  visible.value = !visible.value
}

// 菜单选择
function onMenuSelect({ item, selectedKeys }: any) {
  menuSelectedKeys.value = selectedKeys
  selectedNode.value = item.originItemValue.node
  selectPreview(getPreviewId())
}

// 获取平面视图id
function getPreviewId() {
  const targetDetail = selectedNode.value?.targetDetail
  if (targetDetail === undefined || targetDetail === '')
    return undefined

  const jsonTargetDetail = JSON.parse(targetDetail)

  return isEmpty(jsonTargetDetail) ? jsonTargetDetail : (jsonTargetDetail as any)?.previewId
}

function onOpenChange(openKeys: Key[]) {
  menuOpenKeys.value = openKeys
}

const isListPreview = computed(() => (selectedPreview.value && viewType.value === 'list' && selectedNode.value?.openTarget === 'PREVIEW'))
const isPreview = computed(() => (selectedPreview.value && viewType.value === 'preview' && selectedNode.value?.openTarget === 'PREVIEW'))

const isPreviewDisabled = computed(() => (!selectedPreview.value?.planeViewEnabled && !selectedPreview.value?.threeViewEnabled))
const isListPreviewDisabled = computed(() => (!selectedPreview.value?.listViewEnabled))

function selectPreview(previewId: number) {
  selectedPreview.value = previews.value.find(preview => (preview.id === previewId))
  viewType.value = isPreviewDisabled.value ? 'list' : 'preview'
}
</script>

<template>
  <page-container>
    <absolute-container>
      <div h-50px flex items-center class="head-part">
        <div flex-1 pos-relative>
          <div w-250px flex justify-between items-center w-120px cursor-pointer @click="openOrClose">
            <EnvironmentOutlined />
            <span mr-20px fw-500>{{ selectedNode?.menuName ?? '电子地图' }}</span>
            <AntIcon :icon="visible ? 'UpOutlined' : 'DownOutlined' " />
          </div>
          <a-popover v-model:open="visible" placement="bottomLeft" :arrow="false" trigger="click" style="padding: 0;" @open-change="() => visible = true">
            <template #content>
              <div h-600px w-250px>
                <Scroll>
                  <a-menu :open-keys="menuOpenKeys" :selected-keys="menuSelectedKeys" :items="menuData" mode="inline" @open-change="onOpenChange" @select="onMenuSelect" />
                </Scroll>
              </div>
            </template>
            <span pos-absolute pos-left-0 style="bottom:-10px;" />
          </a-popover>
        </div>

        <div flex-1 justify-center items-center>
          <a-radio-group v-model:value="viewType" button-style="solid">
            <a-radio-button value="preview" :disabled="isPreviewDisabled">
              平面视图
            </a-radio-button>
            <a-radio-button value="list" :disabled="isListPreviewDisabled">
              列表视图
            </a-radio-button>
          </a-radio-group>
        </div>
        <div flex-1 />
      </div>

      <!-- content -->
      <div flex-1 overflow-hidden>
        <Preview v-if="isPreview && selectedNode && selectedPreview" :node="selectedNode" :preview="selectedPreview" />
        <List v-else-if="isListPreview && selectedNode" :node="selectedNode" />
        <Blank v-else :node="selectedNode" />
      </div>
    </absolute-container>
  </page-container>
</template>

<style lang='scss' scoped>
.head-part{
  border-bottom:1px solid var(--pro-ant-color-border)
}
</style>
