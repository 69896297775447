import type * as THREE from 'three'
import { cloneDeep, omit } from 'lodash-es'
import Decimal from 'decimal.js'
import { updatePreviewDeviceRelationApi } from '@/api/relation'

/**
 * 更新圆环的位置和大小（与当前点位保持一致）
 * @param pointCircle
 * @param point
 */
export function updatePointCircle(pointCircle: THREE.Object3D, point: THREE.Object3D): void {
  const { position, scale } = point

  pointCircle.position.copy(position)

  if (pointCircle.name === '3D') {
    pointCircle.scale.set(scale.x, scale.y, scale.z)
  }
  if (pointCircle.name === '2D') {
    pointCircle.scale.copy(scale)
  }
}

/**
 * 更新点位信息
 * @param point
 * @param relation
 * @param preview
 */
export function updatePoint(point: THREE.Object3D, relation: PreviewDeviceRelation, preview: Preview) {
  const { scaling, rotate, axisX, axisY, axisZ } = cloneDeep(relation)
  const eulerRotate = Number(degreesToRadians(rotate).toString())
  point.position.set(axisX, axisY, axisZ)
  point.rotation.set(0, 0, eulerRotate)
  point.userData = cloneDeep(relation)
  if (preview.threeViewEnabled) {
    point.scale.set(scaling * 0.066, scaling * 0.066, scaling * 0.066)
  }

  if (preview.planeViewEnabled) {
    point.scale.set(scaling, scaling, scaling)
  }
}

/**
 * 拖动结束后的操作
 * @param point
 */
export async function pointDragEnd(point: THREE.Object3D) {
  const userData = point.userData as PreviewDeviceRelation

  const { x, y, z } = point.position

  userData.axisX = x
  userData.axisY = y
  userData.axisZ = z

  await updatePreviewDeviceRelationApi(omit(userData, ['id']) as PreviewDeviceRelation)
}

/* *
 * @description 角度转化为弧度
 * 弧度 = (角度 * π) / 180
 * @author 苏俊洋
 * @date 2023/11/16 11:45
 */
function degreesToRadians(degrees: number) {
	 return new Decimal(degrees).times(Math.PI).dividedBy(180)
}
