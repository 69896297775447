const { localVector3ToVector3ViewConfig } = usePointTranslate()

/**
 * 获取平面视图列表
 */
export async function getPreviewsApi() {
  const res = await useGet<Preview[]>('/deploy/preview/list')
  res.forEach((preview) => {
    if (preview.threeViewConfig != null) {
      preview.threeViewConfig = localVector3ToVector3ViewConfig(JSON.parse(preview.threeViewConfig))
    }
  })

  return res
}
