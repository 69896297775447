import * as THREE from 'three'
import { Vector3 } from 'three'
import { DragControls } from 'three/examples/jsm/controls/DragControls.js'
import { resTracker, track } from '../utils/ResourceTracker'
import { pointDragEnd, updatePointCircle } from '../utils/update'
import { createPoint, createTooltip } from '../utils/create'
import { getThreeViewConfig, setOrbitControlsMaxAndMinDistance } from '../utils/getData'
import BaseView from './BaseView'
import Helper from './Helper'
import Loader from './Loader'
import Light from './Light'
import { DISABLE_LAYER, ENABLE_LAYER } from './constant'
import CacheObject from './CacheObject'

export default class ThreeView extends BaseView {
  helper = new Helper()
  loader = new Loader()
  light = new Light()

  cacheObject: CacheObject = new CacheObject()
  private mousePointer = new THREE.Vector2()
  private raycaster = new THREE.Raycaster()
  private dragControls: DragControls | null = null
  // 是否启用拖动控制器
  private enableDragControl = false
  // 是否启用3D点位拖动（启用后，x，y，z方向都可以拖动）
  private enable3DPoint = false

  constructor(domId: string) {
    super(domId)
    if (this.scene) {
      this.light.addAmbientLight(this.scene, track(new THREE.Color('#fff')), 5)

      this.onMouseMove()
      this.updateThreeViewConfig()
    }
  }

  /**
   * 打开拖拽控制器（必须在点位都加载完毕以后，才可以打开）
   */
  openDragControls(listener?: DragCallbackListener) {
    this.enableDragControl = true
    if (this.dragControls == null) {
      this.dragControls = new DragControls(this.cacheObject.dragControlsObjects, this.camera, this.renderer.domElement)
      this.dragPoint(listener)
    }
  }

  /**
   * 打开点位点击事件
   * @param clickCallback
   */
  openClickEvent(clickCallback: (relation: PreviewDeviceRelation) => void) {
    let isDragging = false // 是否正在拖动
    let isClick = false // 是否点击

    document.addEventListener('mousedown', () => {
      isDragging = false // 重置拖动标志
      isClick = true // 设置点击标志
    })

    document.addEventListener('mousemove', () => {
      if (isClick) {
        isDragging = true // 如果鼠标移动时点击标志为true，则发生了拖动
        isClick = false // 重置点击标志
      }
    })

    document.addEventListener('mouseup', async () => {
      if (isClick) {
        // 获取所有与射线相交的物体
        this.raycaster.setFromCamera(this.mousePointer, this.camera)

        const intersects = this.raycaster.intersectObjects(this.cacheObject.raycasterObjects, true)

        if (intersects.length > 0) {
          const object = intersects[0].object

          const point = this.cacheObject.pointCircleObject
          point?.layers.set(ENABLE_LAYER)
          point && updatePointCircle(point, object)

          this.render()
          const relation = object.userData as PreviewDeviceRelation
          await this.gotoAnnotation(relation.threeViewCamera)
          clickCallback(relation)
        }
      }
      else if (isDragging) {
        console.log('拖动')
      }

      isDragging = false // 重置拖动标志
      isClick = false // 重置点击标志
    })
  }

  /**
   * 加载GLTF模型
   * @param preview
   */
  loadGLTFModel(preview: Preview) {
    const { threeViewResourcePath } = preview
    const gltfLoader = this.loader.openGLTFLoader()

    gltfLoader.load(threeViewResourcePath as string, async (gltf) => {
      const model = track(gltf.scene) as THREE.Group
      model.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.material.roughness = 0.2
          child.material.metalness = 0.8
        }
      })

      model.castShadow = false
      model.receiveShadow = false
      model.frustumCulled = false
      this.onMapLoaded(model, preview)
    })
  }

  /**
   * 地图加载完的回调
   * @param model
   * @param preview
   */
  onMapLoaded(model: THREE.Object3D, preview: Preview) {
    const { threeViewConfig } = preview
    // 设置地图视角
    threeViewConfig == null ? this.setMapRemoteView(model, preview) : this.setView(threeViewConfig as ViewConfig<THREE.Vector3>)

    this.cacheObject.relationPreviewIDToPreviewModelMap.set(preview.id, model)

    this.enable3DView(preview.threeViewEnabled)

    this.scene.add(model)
    setOrbitControlsMaxAndMinDistance(model, this.orbitControls)
    this.render()
  }

  /**
   * 设置视角
   * @param threeViewConfig
   */
  setView(threeViewConfig: ViewConfig<THREE.Vector3>) {
    const { cameraLookAt, cameraPosition, controlTarget } = threeViewConfig
    cameraPosition && this.camera.position.copy(cameraPosition)
    cameraLookAt && this.camera.lookAt(cameraLookAt)
    controlTarget && this.orbitControls.target.copy(controlTarget)
  }

  /**
   * @description 加载点位
   */
  loadTextures(url: string, everyLoaded: (texture: THREE.Texture) => void) {
    if (!this.loader.textureLoader)
      return
    this.loader.textureLoader.load(url, (texture) => {
      everyLoaded(track(this.reducedTextureResolution(texture)))
    })
  }

  /* *
	 * @description 加载平面地图
	 */
  loadPreview(url: string, everyLoaded: (texture: THREE.Texture) => void) {
    if (!this.loader.previewLoader)
      return
    this.loader.previewLoader.load(url, (texture) => {
      everyLoaded(track(this.reducedTextureResolution(texture)))
    })
  }

  /* *
	 * @description 点位加载完成后的操作
	 * @param texture
	 * @param relation
	 * @param type
	 */
  afterPointLoaded(texture: THREE.Texture, relation: PreviewDeviceRelation, preview: Preview) {
    if (this.cacheObject.relationRelationIDToPointModelMap.get(relation.id))
      return
    // 创建点位
    const point = createPoint(texture, relation, preview)

    if (point != null) {
      // 创建tooltip
      const tooltip = createTooltip(relation.deviceName, point.position)
      // 将tooltip和point之间的关系添加到map中
      this.cacheObject.relationPointUUIDToTooltipMap.set(point.uuid, tooltip)
      // 添加光线投射对象
      this.cacheObject.raycasterObjects.push(point)
      // 添加被拖拽对象
      this.enableDragControl && this.cacheObject.dragControlsObjects.push(point)
      // 添加relationId和point之间的关系到map中
      this.cacheObject.relationRelationIDToPointModelMap.set(relation.id, point)

      this.scene.add(point)
      this.scene.add(tooltip)
    }
  }

  /**
   * 清楚添加进去的元素
   */
  clear() {
    this.cacheObject.clear()
    this.renderer.renderLists.dispose()
    resTracker.dispose()
  }

  /* *
	 * @description 销毁所有，用于页面完全切换（比视图切换更彻底）
	 */
  destroy() {
    this.clear()
    this.dragControls && this.dragControls.dispose()
    this.orbitControls && this.orbitControls.dispose()
    this.renderer.dispose()
  }

  /* *
	 * @description 减少纹理分辨率
	 */
  private reducedTextureResolution(texture: THREE.Texture) {
    // 设置纹理的minFilter属性为THREE.LinearMipmapLinearFilter
    texture.minFilter = THREE.LinearMipmapLinearFilter
    // 设置wrapS和wrapT属性，这些属性控制纹理在超出UV坐标范围时的行为
    texture.wrapS = THREE.RepeatWrapping
    texture.wrapT = THREE.RepeatWrapping
    return texture
  }

  /**
   * 更新地图视角数据
   */
  private updateThreeViewConfig() {
    this.orbitControls.addEventListener('change', () => {
      const position = this.camera.position
      const target = this.orbitControls.target
      const lookAt = this.camera.getWorldDirection(track(new THREE.Vector3()))
      Object.assign(this.cacheObject.threeViewConfig, {
        cameraPosition: position,
        cameraLookAt: lookAt,
        controlTarget: target,
      })
    })
  }

  /* *
	 * @description 拖动点位
	 */
  private dragPoint(listener?: DragCallbackListener) {
    const startPosition = new Vector3()
    this.dragControls?.addEventListener('drag', (e) => {
      const object = e.object as THREE.Object3D

      this.cacheObject.pointCircleObject?.position.copy(object.position)

      const tooltip = this.cacheObject.relationPointUUIDToTooltipMap.get(object.uuid)
      // 平面地图，z轴不变，3D地图，z轴变
      this.enable3DPoint ? tooltip?.position.copy(object.position) : tooltip?.position.copy(object.position.setZ(1))

      const pointCircle = this.cacheObject.pointCircleObject
      pointCircle != null && updatePointCircle(pointCircle, object)
      listener?.onDrag != null && listener.onDrag(object.userData as PreviewDeviceRelation)
      this.render()
    })

    this.dragControls?.addEventListener('dragstart', (event) => {
      this.orbitControls.enabled = false
      const object = event.object as THREE.Object3D
      const pointCircle = this.cacheObject.pointCircleObject
      pointCircle != null && pointCircle.layers.set(ENABLE_LAYER)
      startPosition.copy(object.position)
      listener?.onStart != null && listener.onStart(object.userData as PreviewDeviceRelation)
    })

    this.dragControls?.addEventListener('dragend', async (event) => {
      this.orbitControls.enabled = true

      const object = event.object as THREE.Object3D
      const distance = startPosition.distanceTo(object.position)

      if (distance > 0) {
        await pointDragEnd(object)
        this.cacheObject.pointCircleObject?.layers.set(ENABLE_LAYER)
        listener?.onEnd != null && listener.onEnd(object.userData as PreviewDeviceRelation)
      }
    })
  }

  /**
   * 添加鼠标移动监听器
   */
  private onMouseMove() {
    window.addEventListener('pointermove', (e) => {
      this.updateMousePointer(e)
      this.raycaster.setFromCamera(this.mousePointer, this.camera)
      const intersects = this.raycaster.intersectObjects(this.cacheObject.raycasterObjects, false)
      if (intersects.length > 0) {
        const object = intersects[0].object as any
        const tooltip = this.cacheObject.relationPointUUIDToTooltipMap.get(object.uuid)
        tooltip?.layers.set(ENABLE_LAYER)
      }
      else {
        this.cacheObject.relationPointUUIDToTooltipMap.forEach((item) => {
          item.layers.set(DISABLE_LAYER)
        })
      }

      this.render()
    })
  }

  getCanvasRelativePositon(event: PointerEvent) {
    if (!this.dom) {
      return { x: 0, y: 0 }
    }
    const rect = this.dom?.getBoundingClientRect()
    return {
      x: (event.clientX - rect.left) * this.dom.clientWidth * window.devicePixelRatio / rect.width,
      y: (event.clientY - rect.top) * this.dom.clientHeight * window.devicePixelRatio / rect.height,
    }
  }

  /**
   * 更新鼠标点位
   * @param event
   */
  private updateMousePointer(event: PointerEvent) {
    const pos = this.getCanvasRelativePositon(event)
    this.mousePointer.x = (pos.x / this.renderer.domElement.width) * 2 - 1
    this.mousePointer.y = (pos.y / this.renderer.domElement.height) * -2 + 1
  }

  /**
   * 启动3D地图的一些功能
   * @param state
   */
  private enable3DView(state: boolean) {
    // 是否是3D的点位
    this.enable3DPoint = state
    // 轨道控制器是否可以旋转
    this.orbitControls.enableRotate = state
  }

  /**
   * 设置模型的初始视角
   * @param model
   * @param preview
   */
  private setMapRemoteView(model: THREE.Object3D, preview: Preview) {
    this.orbitControls.reset()
    const { cameraPosition, cameraLookAt } = getThreeViewConfig(model, preview)
    cameraPosition && this.camera.position.copy(cameraPosition)
    cameraLookAt && this.camera.lookAt(cameraLookAt)
  }
}
