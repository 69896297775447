import * as THREE from 'three'

export default class Light {
  private ambientLight: THREE.AmbientLight

  /**
   * 添加漫反射光
   * @param scene
   * @param color
   * @param intensity
   */
  addAmbientLight(scene: THREE.Scene, color?: THREE.ColorRepresentation | undefined, intensity?: number | undefined) {
    this.ambientLight = new THREE.AmbientLight(color, intensity)
    scene.add(this.ambientLight)
    return this.ambientLight
  }
}
