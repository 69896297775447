import * as THREE from 'three'
import { track } from '../utils/ResourceTracker'

export default class Helper {
  axesHelper: THREE.AxesHelper
  boxHelper: THREE.BoxHelper

  /**
   * 打开坐标系
   * @param scene
   * @param size
   */
  openAxesHelper(scene: THREE.Scene, size: number = 200) {
    this.axesHelper = new THREE.AxesHelper(size)
    scene.add(this.axesHelper)
  }

  /**
   * 用于图形化地展示对象世界轴心对齐的包围盒的辅助对象
   * @param box
   * @param scene
   */
  openBoxHelper(box: THREE.Object3D<THREE.Event>, scene: THREE.Scene) {
    this.boxHelper = track(new THREE.BoxHelper(box, 0xFFFF00))
    scene.add(this.boxHelper)
  }
}
