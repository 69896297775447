import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer.js'
import * as THREE from 'three'

export default class Render {
  private webGLRenderer: THREE.WebGLRenderer
  private css2DRenderer: CSS2DRenderer
  private dom: Element

  constructor(fatherDom: Element) {
    this.dom = fatherDom
  }

  /**
   * 开启WebGLRenderer
   */
  private openWebGLRenderer() {
    // const { clientWidth: width, clientHeight: height } = this.dom
    this.webGLRenderer = new THREE.WebGLRenderer({
      canvas: this.dom,
      antialias: true, // 渲染器锯齿属性，如果不设置，物体的边缘就会有一些毛边
      alpha: true,
    })

    // this.webGLRenderer.setPixelRatio(window.devicePixelRatio) // 设置设备像素比
    // this.webGLRenderer.setSize(width, height)
    // this.dom.appendChild(this.webGLRenderer.domElement)
  }

  /**
   * 获取WebGLRenderer
   */
  getWebGLRenderer() {
    if (this.webGLRenderer == null) {
      this.openWebGLRenderer()
    }
    return this.webGLRenderer
  }

  /**
   * 开启CSS2DRenderer
   */
  private openCSS2DRenderer() {
    const { clientWidth: width, clientHeight: height } = this.dom
    this.css2DRenderer = new CSS2DRenderer()
    this.css2DRenderer.setSize(width, height)
    this.css2DRenderer.domElement.style.position = 'absolute'
    this.css2DRenderer.domElement.style.top = '0'
    // 去除css2d dom中的鼠标事件
    this.css2DRenderer.domElement.style.pointerEvents = 'none'
    this.dom.parentElement?.appendChild(this.css2DRenderer.domElement)
  }

  /**
   * 获取CSS2DRenderer
   */
  getCSS2DRenderer() {
    if (this.css2DRenderer == null) {
      this.openCSS2DRenderer()
    }
    return this.css2DRenderer
  }
}
