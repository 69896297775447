<script setup lang="ts">
import DeviceStatistic from './device-statistic.vue'
import emitter from '@/utils/mitt'
import View from '@/utils/view3D/View'
import { closeSocket, openSocket } from '@/utils/websocket/WebsocketAction'

const props = defineProps<{ node: MenuNode, preview: Preview }>()

const viewContainer = ref()

const deviceDetailVisible = ref(false)
const selectedRelation = ref<PreviewDeviceRelation>()
const statisticVisible = ref(false)
// threejs对象
let view: View | null = null

function onCloseDetail() {
  deviceDetailVisible.value = false
}

onMounted(() => {
  openSocket()
  emitter.on('onDeviceIconChenged', afterDeviceIconChanged)
})

onBeforeUnmount(() => {
  closeSocket()
  emitter.off('onDeviceIconChenged', afterDeviceIconChanged)
})

function afterDeviceIconChanged(message: any) {
  view?.changePointTexture(message)
}

watch(() => props.preview.id, async () => {
  clearMeshesInView()
  await changeView()
  startPointClickListener()
})

onMounted(async () => {
  initView()

  await changeView()
  startPointClickListener()
})

// 初始化3D地图
async function initView() {
  if (view === null) {
    view = new View('map-3d', 'FRONT_END')
  }
}

// 切换平面视图
async function changeView() {
  await view?.changePreview(props.preview)
}

// 开启点位点击监听
function startPointClickListener() {
  view?.onPointClick((relation) => {
    selectedRelation.value = relation
    deviceDetailVisible.value = true
  })
}

// 重置视角
function onResetView() {
  view?.resetView(props.preview)
}

// 打开设备统计modal
function onOpenStatistic() {
  statisticVisible.value = true
}

// 关闭设备统计modal
function onCancelStatistic() {
  statisticVisible.value = false
}

function clearMeshesInView() {
  view?.three.clear()
}
</script>

<template>
  <div ref="viewContainer" h-full w-full pos-relative overflow-hidden>
    <canvas id="map-3d" />
    <div class="operation">
      <a-button type="primary" @click="onResetView">
        重置视角
      </a-button>

      <a-button type="primary" style="margin-left: 10px;" @click="onOpenStatistic">
        设备统计
      </a-button>
    </div>
  </div>

  <DeviceRuntime :visible="deviceDetailVisible" :device-code="selectedRelation?.deviceCode" :device-name="selectedRelation?.deviceName" @close="onCloseDetail" />
  <DeviceStatistic :visible="statisticVisible" :preview-id="preview.id" @cancel="onCancelStatistic" />
</template>

<style lang="scss" scoped>
.operation{
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  border-radius: 5px;
  background-color: rgba($color: #000, $alpha: .3);
}

#map-3d{
  width: 100%;
  height: 100%;
  display: block;
}
</style>
