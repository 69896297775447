import type * as THREE from 'three'
import type { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js'

export default class CacheObject {
  // 点位UUID和tooltip关系Map
  relationPointUUIDToTooltipMap = new Map<$UUID<THREE.Object3D>, CSS2DObject>()

  // 地图id和地图模型的关系map
  relationPreviewIDToPreviewModelMap = new Map<$ID<Preview>, THREE.Object3D>()

  // 点位id和点位模型的关系map
  relationRelationIDToPointModelMap = new Map<$ID<PreviewDeviceRelation>, THREE.Object3D>()

  // 光线交叉到的对象
  raycasterObjects: THREE.Object3D[] = []

  // 拖动控制器要控制的对象
  dragControlsObjects: THREE.Object3D[] = []

  // 点位圆环对象
  pointCircleObject: THREE.Object3D | null = null

  // 地图视角
  threeViewConfig = {} as ViewConfig<THREE.Vector3>

  // 点位非重复texture
  uniqueTextures = new Map<string, THREE.Texture>()

  clear() {
    this.relationPointUUIDToTooltipMap.clear()
    this.relationPreviewIDToPreviewModelMap.clear()
    this.relationRelationIDToPointModelMap.clear()
    this.raycasterObjects.splice(0)
    this.dragControlsObjects.splice(0)
    this.threeViewConfig = {} as ViewConfig<THREE.Vector3>
    this.pointCircleObject = null
    this.uniqueTextures.clear()
  }
}
