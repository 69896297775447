import * as THREE from 'three'
import type { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { track } from './ResourceTracker'

/**
 * 获取模型的threeViewConfig
 * @param model
 * @param preview
 */
export function getThreeViewConfig(model: THREE.Object3D, preview: Preview): ViewConfig<THREE.Vector3> {
  const { radius, center } = getModelCenterAndRadius(model)

  const cameraPosition = track(new THREE.Vector3(radius, radius, radius)) as THREE.Vector3
  const cameraLookAt = center
  const controlTarget = track(new THREE.Vector3(0, 0, 0)) as THREE.Vector3

  if (preview.planeViewEnabled) {
    cameraPosition.set(0, 0, radius)
  }

  return { cameraPosition, cameraLookAt, controlTarget }
}

/**
 * 获取模型的半径和中心
 * @param model
 */
function getModelCenterAndRadius(model: THREE.Object3D) {
  const boundingBox = (track(new THREE.Box3()) as THREE.Box3).setFromObject(model)
  const center = boundingBox.getCenter(track(new THREE.Vector3()))
  // 设置相机的视场角
  const sphere = boundingBox.getBoundingSphere(track(new THREE.Sphere()))
  const radius = sphere.radius
  return { radius, center }
}

/**
 * 设置模型的缩放最大距离和最小距离
 * @param model
 * @param controls
 */
export function setOrbitControlsMaxAndMinDistance(model: THREE.Object3D, controls: OrbitControls) {
  const boundingBox = new THREE.Box3()
  boundingBox.setFromObject(model)

  const size = new THREE.Vector3()
  boundingBox.getSize(size)

  const maxDistance = Math.max(size.x, size.y, size.z) // 调整倍数以适应你的需求
  const minDistance = maxDistance / 5 // 根据你的需求设置最小距离

  controls.maxDistance = maxDistance
  controls.minDistance = minDistance
}
