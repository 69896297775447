import type { Tween } from '@tweenjs/tween.js'
import TWEEN from '@tweenjs/tween.js'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import type { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer.js'
import { track } from '../utils/ResourceTracker'
import Render from './Render'
import { DISABLE_LAYER, ENABLE_LAYER } from './constant'

const easing = TWEEN.Easing.Cubic.InOut
const DURATION = 1000
const message = useMessage()

export default class BaseView {
  scene: THREE.Scene // 场景
  renderObj: Render
  renderer: THREE.WebGLRenderer // 渲染器
  css2DRenderer: CSS2DRenderer
  camera: THREE.PerspectiveCamera // 相机
  orbitControls: OrbitControls // 相机控件
  dom: Element | null = null

  private animateId: number

  constructor(domId: string) {
    if (!domId) {
      console.warn('没有传入domid')
      return
    }

    this.dom = document.getElementById(domId)

    if (!this.dom) {
      console.warn('找不到节点')
      return
    }

    this.init(this.dom)
    this.addListener()
  }

  /* *
	 * @description 设置背景色
	 */
  setRendererBackgroundColor(color: string | null) {
    if (color != null) {
      this.renderer.setClearColor(track(new THREE.Color(color)), 1)
    }
    else {
      this.renderer.setClearAlpha(0)
    }
  }

  private init(dom: Element) {
    this.renderObj = new Render(dom)
    this.renderer = this.renderObj.getWebGLRenderer()
    this.css2DRenderer = this.renderObj.getCSS2DRenderer()

    const { clientWidth: width, clientHeight: height } = dom

    this.setScene()
    this.setCamera(width, height)
    this.setOrbitControls()
    // dom.appendChild(this.renderer.domElement)
    this.render()
    this.animate()
  }

  /**
   * 添加场景
   */
  private setScene() {
    this.scene = new THREE.Scene()
    // this.scene.background = null;
  }

  /**
   * 添加透视投影相机
   * @param domWidth
   * @param domHeight
   * @param fov 相机视锥体竖直方向视野角度
   * @param near 相机视锥体近裁截面相对相机距离
   * @param far 相机视锥体远裁截面相对相机距离，far-near构成了视锥体高度方向
   */
  private setCamera(domWidth: number, domHeight: number, fov: number = 75, near: number = 1, far: number = 8000) {
    this.camera = new THREE.PerspectiveCamera(fov, domWidth / domHeight, near, far)
    this.camera.position.set(0, 200, 200)
    this.camera.lookAt(0, 0, 0)

    this.camera.layers.disable(DISABLE_LAYER)
    this.camera.layers.enable(ENABLE_LAYER)
  }

  /**
   * 添加相机控制器
   */
  private setOrbitControls() {
    this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement)
    this.orbitControls.mouseButtons = {
      LEFT: THREE.MOUSE.PAN, // 鼠标左键，摄像机平移
      RIGHT: THREE.MOUSE.ROTATE, // 鼠标右键，旋转
      MIDDLE: THREE.MOUSE.DOLLY, // 鼠标中键，缩放
    }

    this.orbitControls.maxDistance = 1000

    this.orbitControls.addEventListener('change', () => {
      this.render()
    })
  }

  /**
   * 渲染函数
   */
  render() {
    this.resetRenderSize()
    this.renderer.render(this.scene, this.camera)
    this.css2DRenderer.render(this.scene, this.camera)
  }

  /**
   * 重置地图
   * @param dom
   */
  resetRenderSize() {
    if (!this.resizeRendererToDisplaySize(this.renderer))
      return

    const { clientWidth, clientHeight } = this.renderer.domElement
    this.camera.aspect = clientWidth / clientHeight
    this.camera.updateProjectionMatrix()
  }

  resizeRendererToDisplaySize(renderer: THREE.WebGLRenderer) {
    const rendererDom = renderer.domElement
    // TODO
    const pixelRatio = window.devicePixelRatio
    // const pixelRatio = 1
    const width = Math.floor(rendererDom.clientWidth * pixelRatio)
    const height = Math.floor(rendererDom.clientHeight * pixelRatio)
    const needResize = rendererDom.width !== width || rendererDom.height !== height
    if (needResize) {
      // renderer.setPixelRatio(pixelRatio)
      renderer.setSize(width, height, false)
    }

    return needResize
  }

  addListener() {
    window.addEventListener('resize', () => { this.render() })
    const resizeObserver = new ResizeObserver(() => {
      this.render()
    })

    if (this.dom && this.dom.parentElement) {
      resizeObserver.observe(this.dom.parentElement)
    }
  }

  /**
   * 动画
   */
  protected animate() {
    this.animateId = requestAnimationFrame(() => {
      this.animate()
    })

    if (this.orbitControls != null) {
      this.orbitControls.update()
    }

    TWEEN.update()
  }

  /**
   * 取消动画
   */
  protected cancelAnimate() {
    this.animateId && cancelAnimationFrame(this.animateId)
  }

  /**
   * 场景转换
   * @param threeViewConfig
   */
  async gotoAnnotation(threeViewConfig: ViewConfig<THREE.Vector3>) {
    if (threeViewConfig == null)
      return

    let cameraLookAtTween: any
    let controlTween: any
    let cameraPositionTween: any

    const { cameraPosition: aimCameraPosition, controlTarget: aimControlTarget, cameraLookAt: aimCameraLookAt } = threeViewConfig
    const originCameraPosition = this.camera.position
    const originCameraLookAt = this.camera.getWorldDirection(track(new THREE.Vector3()))
    const originControlTarget = this.orbitControls.target
    const tweens: Tween<THREE.Vector3>[] = []

    if (aimCameraLookAt && originCameraLookAt) {
      cameraLookAtTween = new TWEEN.Tween(originCameraLookAt).to(aimCameraLookAt, DURATION).easing(easing)
      tweens.push(cameraLookAtTween)
    }
    if (aimControlTarget && originControlTarget) {
      controlTween = new TWEEN.Tween(originControlTarget).to(aimControlTarget, DURATION).easing(easing)
      tweens.push(controlTween)
    }
    if (aimCameraPosition && originCameraPosition) {
      cameraPositionTween = new TWEEN.Tween(originCameraPosition).to(aimCameraPosition, DURATION).easing(easing)
      tweens.push(cameraPositionTween)
    }

    cameraPositionTween && cameraPositionTween.stop()
    cameraLookAtTween && cameraLookAtTween.stop()
    controlTween && controlTween.stop()

    return await Promise.all(
      tweens.map((tween) => {
        return new Promise((resolve) => {
          tween.onComplete(resolve)
          tween.start()
        })
      }),
    )
  }
}
