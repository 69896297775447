import type * as THREE from 'three'
import { DISABLE_LAYER } from './threejs/constant'
import { create2DMapMesh, createPointCircle } from './utils/create'
import ThreeView from './threejs/ThreeView'
import { updatePoint, updatePointCircle } from './utils/update'
import { getThreeViewConfig } from './utils/getData'
import { getPreviewDeviceRelationsApi } from '@/api/relation'

const { localVector3ToVector3ViewConfig } = usePointTranslate()

export default class View {
  public three: ThreeView
  private runtimeIcon = false
  // 请求接口得到的点位信息
  private relations: PreviewDeviceRelation[] = []

  constructor(domId: string, endType: EndType) {
    this.three = new ThreeView(domId)
    this.separate(endType)
  }

  openDrag(listener: DragCallbackListener) {
    this.three.openDragControls(listener)
  }

  /**
   * 销毁dom
   */
  destroyCanvas() {
    this.three.renderer.domElement.remove()
    this.three.css2DRenderer.domElement.remove()
    this.three.destroy()
  }

  /**
   * 更新点位的userData信息
   * @param relation
   * @param preview
   */
  updatePointAndPointCircle(relation: PreviewDeviceRelation, preview: Preview) {
    const point = this.three.cacheObject.relationRelationIDToPointModelMap.get(relation.id)

    if (point != null) {
      updatePoint(point, relation, preview)
      const pointCircle = this.three.cacheObject.pointCircleObject
      pointCircle && updatePointCircle(pointCircle, point)
      this.three.render()
    }
  }

  /**
   * 打开点击事件
   * @param callback
   */
  onPointClick(callback: (relation: PreviewDeviceRelation) => void) {
    this.three.openClickEvent(callback)
  }

  /**
   * 监听相机信息
   * @param callback
   */
  onListenViewConfig(callback: (viewConfig: ViewConfig<THREE.Vector3>) => void) {
    this.three.orbitControls.addEventListener('change', () => {
      callback(this.three.cacheObject.threeViewConfig)
    })
  }

  /**
   * 重置视角
   * @param preview
   */
  resetView(preview: Preview) {
    const { threeViewConfig } = preview

    let nextThreeViewConfig: ViewConfig<THREE.Vector3> | null = null

    if (threeViewConfig) {
      nextThreeViewConfig = localVector3ToVector3ViewConfig(threeViewConfig as ViewConfig<LocalVector3>)
    }
    else {
      const model = this.three.cacheObject.relationPreviewIDToPreviewModelMap.get(preview.id)

      if (model == null) {
        console.warn('地图模型不存在')
        return
      }

      nextThreeViewConfig = getThreeViewConfig(model, preview)
    }

    nextThreeViewConfig != null && this.three.gotoAnnotation(nextThreeViewConfig)
  }

  /* *
	 * @description 切换地图和点位
	 * @param preview
	 */
  async changePreview(preview: Preview) {
    this.three.clear()
    this.refreshMap(preview)
    await this.refreshPoints(preview)
  }

  /**
   * 更改点位图标
   * @param socketMessage websocket消息
   */
  changePointTexture(socketMessage: { deviceCode: string, iconPath: string }) {
    const relationId = this.relations.find(relation => relation.deviceCode === socketMessage.deviceCode)?.id
    const aimPoint = this.three.cacheObject.relationRelationIDToPointModelMap.get(relationId as number) as any
    if (aimPoint == null)
      return
    this.three.loadTextures(socketMessage.iconPath, (texture) => {
      if (aimPoint.material.map) {
        aimPoint.material.map.dispose()
        aimPoint.material.map = null
      }

      this.updateUniqueTexture(socketMessage.iconPath, texture)
      // 更新纹理贴图
      aimPoint.material.map = texture
      aimPoint.material.needsUpdate = true
    })
  }

  private updateUniqueTexture(iconPath: string, texture: THREE.Texture) {
    const res = this.three.cacheObject.uniqueTextures.get(iconPath)
    if (res == null) {
      this.three.cacheObject.uniqueTextures.set(iconPath, texture)
    }
  }

  /**
   * 根据前台还是后台，决定开启哪些功能
   * @param endType
   */
  private separate(endType: EndType) {
    if (endType === 'BACK_END') {
      this.runtimeIcon = false
    }

    if (endType === 'FRONT_END') {
      this.runtimeIcon = true
    }
  }

  /* *
	 * @description 刷新地图
	 * @param preview
	 */
  private refreshMap(preview: Preview) {
    const { threeViewEnabled, planeViewEnabled, planeViewBackgroundPath } = preview
    this.three.setRendererBackgroundColor(preview.planeViewBackgroundColor)
    // 加载3D地图
    if (threeViewEnabled) {
      this.three.loadGLTFModel(preview)
    }
    // 加载2D平面地图
    if (planeViewEnabled) {
      this.three.loader.openPreviewLoader()
      this.three.loadPreview(planeViewBackgroundPath as string, (texture) => {
        this.three.onMapLoaded(create2DMapMesh(texture), preview)
      })
      this.three.render()
    }
  }

  /* *
	 *  @description 刷新点位
	 *  @param preview
	 */
  private async refreshPoints(preview: Preview) {
    // runtimeIcon 前台用true，后台用false
    this.relations = await getPreviewDeviceRelationsApi(preview.id, this.runtimeIcon)

    this.three.loader.openTexturesLoader(() => {
      this.relations.forEach((relation) => {
        const texture = this.three.cacheObject.uniqueTextures.get(relation.iconAccessPath)
        if (texture) {
          this.three.afterPointLoaded(texture, relation, preview)
        }
      })
      this.three.render()
    })

    this.uniqueRelationByIconPath(this.relations).forEach((relation) => {
      this.three.loadTextures(relation.iconAccessPath, (texture) => {
        this.three.cacheObject.uniqueTextures.set(relation.iconAccessPath, texture)
      })
    })

    if (this.three.cacheObject.pointCircleObject == null) {
      const pointCircle = createPointCircle(preview)
      pointCircle?.layers.set(DISABLE_LAYER)
      this.three.cacheObject.pointCircleObject = pointCircle
      pointCircle && this.three.scene.add(pointCircle)
    }
  }

  private uniqueRelationByIconPath(relations: PreviewDeviceRelation[]) {
    return relations.reduce((prev: PreviewDeviceRelation[], curr: PreviewDeviceRelation) => {
      const isExist = prev.findIndex(relation => relation.iconAccessPath === curr.iconAccessPath)
      if (isExist === -1) {
        prev.push(curr)
      }
      return prev
    }, [])
  }
}
