import * as THREE from 'three'

export function usePointTranslate() {
  // 将LocalViewConfig转换为ThreeViewConfig
  function localVector3ToVector3ViewConfig(localViewConfig: ViewConfig<LocalVector3>) {
    if (localViewConfig == null)
      return null
    const { cameraLookAt, cameraPosition, controlTarget } = localViewConfig
    return {
      cameraLookAt: localVector3ToVector3(cameraLookAt),
      cameraPosition: localVector3ToVector3(cameraPosition),
      controlTarget: localVector3ToVector3(controlTarget),
    }
  }

  // 将ThreeViewConfig转换为LocalViewConfig
  function vector3ToLocalVector3ViewConfig(threeViewConfig: ViewConfig<THREE.Vector3>) {
    if (threeViewConfig == null)
      return null
    const { cameraLookAt, cameraPosition, controlTarget } = threeViewConfig
    return {
      cameraPosition: vector3ToLocalVector3(cameraPosition),
      cameraLookAt: vector3ToLocalVector3(cameraLookAt),
      controlTarget: vector3ToLocalVector3(controlTarget),
    }
  }

  function vector3ToLocalVector3(vector3: THREE.Vector3) {
    const { x, y, z } = vector3
    return { x, y, z }
  }

  function localVector3ToVector3(vector: LocalVector3) {
    const { x, y, z } = vector
    return new THREE.Vector3(x, y, z)
  }

  return { localVector3ToVector3ViewConfig, vector3ToLocalVector3ViewConfig, localVector3ToVector3, vector3ToLocalVector3 }
}
