import * as THREE from 'three'
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js'
import { DISABLE_LAYER, MAP_2D_HEIGHT, MAP_2D_WIDTH } from '../threejs/constant'
import { track } from './ResourceTracker'
import { updatePoint } from './update'

/**
 * 创建Sprite类型点位
 * @param texture
 * @param relation
 * @param preview
 */
export function createPoint(texture: THREE.Texture, relation: PreviewDeviceRelation, preview: Preview) {
  const { threeViewEnabled, planeViewEnabled } = preview

  let point: THREE.Object3D | null = null

  if (threeViewEnabled) {
    point = createSprite(texture)
  }

  if (planeViewEnabled) {
    point = createCircleMesh(texture)
  }

  point != null && updatePoint(point, relation, preview)

  return point
}

/**
 * 创建Sprite类型点位
 * @param texture
 */
function createSprite(texture: THREE.Texture) {
  const material = track(
    new THREE.SpriteMaterial({
      map: texture,
      depthTest: false,
      depthWrite: false,
      transparent: true,
      sizeAttenuation: false,
    }),
  )

  return track(new THREE.Sprite(material)) as THREE.Sprite
}

/**
 * 创建圆环型Mesh类型点位
 * @param texture
 */
function createCircleMesh(texture: THREE.Texture) {
  const geometry = track(new THREE.CircleGeometry(30, 32)) as THREE.CircleGeometry
  const material = track(
    new THREE.MeshBasicMaterial({
      side: THREE.DoubleSide,
      map: texture,
    }),
  ) as THREE.MeshBasicMaterial
  material.transparent = true

  return track(new THREE.Mesh(geometry, material)) as THREE.Mesh
}

/**
 * 创建2D地
 * @param texture
 */
export function create2DMapMesh(texture: THREE.Texture) {
  const material = track(new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide, transparent: true }))
  const geometry = track(new THREE.PlaneGeometry(MAP_2D_WIDTH, MAP_2D_HEIGHT))
  return track(new THREE.Mesh(geometry, material)) as THREE.Mesh
}

/**
 * 创建点位信息提示框
 */
export function createTooltip(tooltipText: string, position: THREE.Vector3) {
  const fatherDiv = document.createElement('div')
  fatherDiv.style.position = 'relative'

  const label = track(new CSS2DObject(fatherDiv)) as CSS2DObject
  label.position.copy(position)

  const labelSonDiv = document.createElement('div')
  labelSonDiv.style.position = 'absolute'
  labelSonDiv.style.zIndex = '4'
  labelSonDiv.style.backgroundColor = 'rgba(0, 0, 0, 0.6)'
  labelSonDiv.style.padding = '10px'
  labelSonDiv.style.borderRadius = '5px'
  labelSonDiv.style.color = '#3afdff'
  labelSonDiv.style.bottom = '27px'
  labelSonDiv.style.wordBreak = 'keep-all'
  labelSonDiv.style.transform = 'translateX(-50%)'

  labelSonDiv.innerHTML = `${tooltipText}`

  fatherDiv.appendChild(labelSonDiv)
  // 将tooltip初始为隐藏层
  label.layers.set(DISABLE_LAYER)
  return label as CSS2DObject
}

/**
 * 根据开启地图的类型（2D、3D）类创建对应的点位的选中圆环
 * @param preview
 */
export function createPointCircle(preview: Preview) {
  const { threeViewEnabled, planeViewEnabled } = preview
  if (threeViewEnabled) {
    return createSpriteBackground()
  }
  else if (planeViewEnabled) {
    return createMeshBackground()
  }
  else {
    return null
  }
}

/**
 * 创建Mesh材质的背景
 */
function createMeshBackground() {
  const material = track(new THREE.MeshBasicMaterial({ color: 0xFF0000 })) // 创建红色材质

  const geometry = track(new THREE.RingGeometry(30, 32, 30, 10, 0, Math.PI * 2))
  const circleMesh = track(new THREE.Mesh(geometry, material)) as THREE.Mesh
  circleMesh.name = '2D'
  return circleMesh
}

/**
 * 创建Sprite的背景
 */
function createSpriteBackground() {
  // 创建画布
  const canvas = document.createElement('canvas')
  canvas.width = 128
  canvas.height = 128

  // 获取2D上下文对象
  const ctx = canvas.getContext('2d')
  let texture
  if (ctx) {
    // 绘制圆形
    ctx.beginPath()
    ctx.arc(64, 64, 60, 0, 2 * Math.PI)
    ctx.lineWidth = 5
    ctx.strokeStyle = '#ff0000'
    ctx.stroke()
    // 创建贴图
    texture = track(new THREE.Texture(canvas))
    texture.needsUpdate = true
  }

  const material = track(
    new THREE.SpriteMaterial({
      map: texture,
      depthTest: false,
      depthWrite: false,
      transparent: true,
      sizeAttenuation: false,
    }),
  )

  const sprite = track(new THREE.Sprite(material)) as THREE.Sprite
  sprite.name = '3D'

  return sprite
}
